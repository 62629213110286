import React from "react"
import PropTypes from "prop-types"
import { Link, graphql, StaticQuery } from "gatsby"

const BlogRoll = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark

  return (
    <div className="py-10 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-y-8 gap-x-6">
      {posts &&
        posts.map(({ node: post }) => (
          <article key={post.id} className="bg-slate-100 min-h-min">
            <header className="p-3 font-raleway text-center bg-gray-200 font-semibold">
              <Link to={post.id}>{post.frontmatter.title}</Link>
            </header>
            <Link to={post.id}>
              <div className="p-4 text-sm self-stretch">
                {post.frontmatter.description}
              </div>
            </Link>
          </article>
        ))}
    </div>
  )
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

const query = () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              frontmatter {
                path
                title
                description
                date(formatString: "MMMM DD, YYYY")
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
)

export default query
