import React from "react"
import Layout from "../components/layout"
import BlogRoll from "../components/BlogRoll"
import Seo from "../components/seo"

const BlogIndexPage = () => {
  return (
    <Layout>
      <Seo title="Статьи" />
      <div className="py-10 font-raleway px-4 ms:px-0">
        <h1 className="text-3xl font-semibold">Статьи</h1>
        <BlogRoll />
      </div>
    </Layout>
  )
}

export default BlogIndexPage
